<template>
	<div class="flexPage themeColor">
		<navbar :params="pageInfo" />
		<div class="title">Function</div>
		<div class="create">{{ $t('other.translate143') }}</div>
		<div class="createC">{{ $t('other.translate141') }}</div>
		<el-input v-model="userName" :placeholder="$t('other.translate137')" style="margin-top: 24px"></el-input>
		<el-input v-model="password" :placeholder="$t('other.translate138')" type="password"
			:show-password="true"></el-input>
		<el-input v-model="surePassword" :placeholder="$t('other.translate142')" type="password"
			:show-password="true"></el-input>
		<div class="btn" @click="register">{{ $t('other.translate150') }}</div>
		<div class="already">{{ $t('other.translate145') }}! <span @click="goLogin">{{ $t('other.translate6') }}</span>
		</div>
		<div class="footer">{{ $t('other.translate146') }} <span style="color: #000">{{ $t('other.translate147')
				}}</span> {{ $t('other.translate149') }}<span style="color: #000"> {{ $t('other.translate148') }}
			</span>
		</div>
		<!-- <div class="signHead">
			<span class="signLogo"><img v-if="$store.state.app.nightModel" src="../../assets/img/logo2.png" alt=""><img
					v-if="!$store.state.app.nightModel" src="../../assets/img/logo2_white.png" alt=""></span>
		</div>
	</div>
	<div class="tabs-boxes">
		<span @click="tabActive = 0" class="label-way" :class="[tabActive === 0 ? 'tab-active' : '']">{{
			$t('form.signin.submit') }}</span>
		<span @click="tabActive = 1" class="label-way ml10" :class="[tabActive === 1 ? 'tab-active' : '']">{{
			$t('form.signup.submit')
		}}</span>
	</div> -->
		<!-- <template v-if="tabActive === 0">
			<Login />
		</template>
<template v-if="tabActive === 1">
			<Register />
		</template> -->
	</div>
</template>
<script>
import navbar from '@/views/modules/navbar.vue';
// import Login from './components/login.vue';
// import Register from './components/register.vue'
export default {
	data() {
		return {
			pageInfo: {
				customTitle: '',
				border: false,
				navbarStyle: 'normal'
			},
			tabActive: 0,
			userName: '',
			password: '',
			surePassword: ''
		}
	},
	components: { navbar },
	watch: {},
	computed: {},
	mounted() { },
	methods: {
		goLogin() {
			this.$mts.goto('signin')
		},
		register() {
			let that = this;
			// if (!that.$vld.checkValue(this.userName, that.active)) {
			//     that.$toast(that.$t("validate.signup.usernameError"));
			//     return;
			// }
			// let reg = /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))[a-zA-Z0-9]{6,20}$/;
			// if (!reg.test(this.form.password)) {
			//     that.$toast(that.$t("validate.public.passwordError"));
			//     return;
			// }
			// if (this.form.password != this.form.twice_password) {
			//     that.$toast(that.$t("validate.public.twicePassword"));
			//     return;
			// }
			// if (!that.$vld.checkValue(this.form.code, 'sms4')) {
			//     that.$toast(that.$t("validate.public.smsError"));

			//     return;
			// }
			// //邀请码非必填
			// // if(that.form.invitationCode){
			// // 	that.$toast(that.$t("validate.public.inviteCode"));
			// // 	return;
			// // }
			// this.submiting = true;
			// that.submitStatus = true;

			let params = {
				email: that.userName,
				username: that.userName,
				password: that.password,
				// code: '1233',
				// country: that.$t('assets.kyc.china'),
				// promotion: that.form.invitationCode,
				// superPartner: '',
				// ticket: that.form.ticket,
				// randStr: that.form.randStr,
				// invitationCode: that.form.invitationCode,
				// type: that.active,
				// gt_server_status: that.dto.gt_server_status,
				// txHash: that.dto.txHash
			}
			if (that.password != that.surePassword) {
				that.$toast(that.$t("other.translate153"));
			} else {
				this.$http.post(this.host + '/uc/register/byEmail', params).then((response) => {
					let resp = response.body;
					if (resp.code == 0) {
						that.$toast(that.$t("validate.signup.success"));
						that.$router.push({ name: 'signin' });
					} else {
						that.$toast(response.data.message);
					}
				});
			}

		}
	}
}
</script>
<style scoped>
.footer {
	width: 327px;
	margin: 0 auto;
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	color: #828282;
	margin-top: 24px;
}

.already {
	width: 100%;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	text-align: center;
	margin-top: 135px;
}

.btn {
	width: 327px;
	height: 40px;
	background: #000000;
	border-radius: 8px;
	margin: 0 auto;
	margin-top: 16px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 40px;
	text-align: center;
	color: #fff
}

::v-deep .el-input__inner {
	border-radius: 8px;
}

::v-deep .el-input {
	width: 327px;
	height: 40px;
	border-radius: 8px;
	margin: 0 auto;
	margin-top: 8px;
}

.createC {
	width: 100%;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	text-align: center;

}

.create {
	width: 100%;
	font-family: Inter;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	margin-top: 112px;

}

.title {
	width: 100%;
	font-family: Inter;
	font-size: 24px;
	font-weight: 600;
	text-align: center;
}
</style>
